<template>
	<div class="">
		<div class="tableCon" v-loading="loading_base" :element-loading-text="$t('47df8be257c59dde')">
			<div class="tableConTable">
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('ca5a01a5adf20fe7')}}</el-button>
								<span class="tct_tit">{{$t('i18nn_4de0a112bb772da8')}}</span>
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
							</el-button>
						</el-col>
					</el-row>
				</div>
			</div>

			<el-card shadow="never" style="margin-bottom: 5px;">
				<!-- <div style="display: flex;justify-content: space-between;"> -->
				<!-- <div style="flex:1"> -->
				<el-descriptions title="" :column="4">

					<el-descriptions-item :label="$t('i18nn_559fed47b0e95407')">{{baseData.inWhCode}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_8af1fd820046d3c1')">{{baseData.whNo}}</el-descriptions-item>


					<el-descriptions-item :label="$t('i18nn_0ae081c3b8c4d4a1')">{{baseData.commitDate}}</el-descriptions-item>

					<el-descriptions-item :label="$t('i18nn_9be246e60da2a0a2')">{{baseData.arriveWhDate}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_3d3bc466f13154d4')+'/'+$t('i18nn_daa60c502de1462f')">{{baseData.relationNo}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_8221d63993501b1c')">{{baseData.arriveWhWayName}}</el-descriptions-item>
					
					<el-descriptions-item :label="$t('i18nn_6e33a5e062b119ff')">{{baseData.isShowSkuName }}}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_e137950d1897817e')">{{baseData.isLiveUploadName}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_e1e2b21c7b4b951f')">{{baseData.trOpTypeName}}</el-descriptions-item>
				</el-descriptions>
				
				<!-- <el-descriptions title="" :column="4" v-if="'10'==baseData.trOpType">
					<el-descriptions-item label="是否FBA">{{baseData.isFbaName}}</el-descriptions-item>
					<el-descriptions-item label="FBA仓库编号">{{baseData.fbaAddrCode}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_8758fd50c87d6c9c')">
						{{ baseData.addr }},{{ baseData.city }},{{ baseData.state }},{{ baseData.country }}
					</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_5a9fb0915ecea987')">{{baseData.phone}}</el-descriptions-item>
					<el-descriptions-item :label="$t('c4913ab43009b365')">{{baseData.postalCode}}</el-descriptions-item>
				</el-descriptions> -->
				
				<el-descriptions title="">
					<el-descriptions-item :label="$t('f5d43732e3f6cf4d')">
						<ul>
							<li v-for="(item,index) in baseData.fileList" :key="index">
								<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
									:fit="'contain'" :src="item.url" :preview-src-list="baseData.fileList.map(itemPre=> { return itemPre.url})">
									<div slot="error" class="image-slot">
									  <i class="el-icon-document"></i>
									</div>
								</el-image>
								<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
							</li>
						</ul>
					</el-descriptions-item>
				</el-descriptions>
				
				<el-descriptions title="">
					<el-descriptions-item :label="$t('15b3627faddccb1d')">
						<span style="color:#e6a23c">
							{{baseData.remark}}
						</span>
					</el-descriptions-item>
				</el-descriptions>

			</el-card>


			<el-card shadow="never" v-loading="loading_load" style="margin-bottom: 5px;">
				<div slot="header" class="">
					<div style="display: flex;justify-content: space-between;">
						<div>
							<span>{{$t('i18nn_3dc377fecc5acdb1')}}</span>
						</div>
					</div>
				</div>


				<el-table id="out-table2" ref="multipleTable" :data="baseData.recoreList" stripe :border="true"
					:max-height="$store.state.tableMaxHeight5" style="width: 100%" size="small">
					<!-- <el-table-column v-if="isSel" type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column> -->
					<el-table-column type="index" fixed="left" width="50" align="center"
						:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						
						<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
							<template slot-scope="scope">
								<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="warning" v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="danger" v-else-if="'28' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="warning" v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="info" v-else-if="'36' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							</template>
						</el-table-column>

					<el-table-column prop="verifyStatusName" :label="$t('hytxs0000045')">
						<template slot-scope="scope">
							<el-tag type="" v-if="'0' == scope.row.verifyStatus">
								<!-- {{ scope.row.verifyStatusName }} -->
								{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}
							</el-tag>
							<el-tag type="success" v-else-if="'1' == scope.row.verifyStatus">
								<!-- {{ scope.row.verifyStatusName }} -->
								{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}
							</el-tag>
							<el-tag type="info" v-else>
								<!-- {{ scope.row.verifyStatusName }} -->
								{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}
							</el-tag>
						</template>
					</el-table-column>

					<!-- <el-table-column prop="inWhTypeName" :label="$t('b799f516e3940e3f')" width="">
					</el-table-column> -->
					
					<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
					</el-table-column>
					
					<el-table-column prop="ctnCount" :label="$t('i18nn_bdc361ba04506136')" width="">
					</el-table-column>
					
					<el-table-column prop="inWhPallte" :label="$t('i18nn_83125000f5142ed1')" width="">
					</el-table-column>

					<el-table-column prop="ctnUnitName" :label="$t('b6bf0a07fe26f74f')" width="">
					</el-table-column>

					<el-table-column prop="ctnL" :label="$t('i18nn_53f9f6f694a5b0a8')+'(L*W*H)'" min-width="180">
						<template slot-scope="scope">
							<div >
								{{scope.row.ctnL}}*{{scope.row.ctnW}}*{{scope.row.ctnH}}
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="ctnWeight" :label="$t('i18nn_9e168db87b716866')" width="">
					</el-table-column>

					<el-table-column prop="goodsSku" :label="$t('i18nn_c5ef0b068e92e23d')+'SKU'" width="" v-if="'1'==baseData.isShowSku">
					</el-table-column>

					<el-table-column prop="ctnSkuCount" :label="$t('i18nn_85a10d3e5ea0e103')" width="" v-if="'1'==baseData.isShowSku">
					</el-table-column>

					<el-table-column prop="skuTotal" :label="$t('i18nn_bad53577db0da2d3')" width="" v-if="'1'==baseData.isShowSku">
					</el-table-column>
					<!-- <el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
						<template slot-scope="scope">
							<el-popover placement="top"
						    trigger="hover"
						    ><div class="pre-text">{{ scope.row.remark }}</div>
						    <span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						  </el-popover>
						</template>
					</el-table-column> -->
					<el-table-column :label="$t('Storage.tableColumn.operation')" width="160px" fixed="right">
						<template slot-scope="scope">
							<div>
								<el-button @click="showDetList($event, scope.row)" type="primary" size="mini" icon="el-icon-view">{{$t('i18nn_202e232a73f0abe1')}}</el-button>
							</div>
							
						</template>
					</el-table-column>
				</el-table>
			</el-card>
		</div>
		
		<!-- 出库明细 -->
		<TransportInventoriesOutWhDet :openTime="TraInvOutDetOpenTime" :inWhRecord="TraInvOutDetInWhRecord" :row="TraInvOutDetRow"></TransportInventoriesOutWhDet>
		
	</div>
</template>
<script>
	// import { deepClone } from '@/utils/utils.js';
	// import WhTransportConfirmReceiptCom from '@/components/WarehouseCenter2/Transport/WhTransportConfirmReceiptCom.vue';
	import TransportInventoriesOutWhDet from '@/components/StorageCenter/Transport/TransportInventoriesOutWhDet.vue';
	
	export default {
		components: {
			// WhTransportConfirmReceiptCom
			TransportInventoriesOutWhDet
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false;
		// 		},
		// 		type: Boolean
		// 	},
		// 	status: {
		// 		default: function() {
		// 			return '';
		// 		},
		// 		type: String
		// 	}
		// },
		data() {
			return {
				id: "",
				
				TraInvOutDetOpenTime:"",
				TraInvOutDetRow:{},
				TraInvOutDetInWhRecord:"",

				//选择
				currentSel: {},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				// tableData: [],
				//缓存，原数据
				// tableDataCatch: [],
				baseData: {},
				loading_base: false,


				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//表格选择的数据
				multipleSelection: [],
				//查询，排序方式
				//"orderBy": "auditTime,fCon,goodsCon", //排序字段
				selectOption: {
					// wh_size_unit:[],
					wh_size_unit: [],
					wh_transfer_Inwh_type: [],
					wh_transfer_arrive_way: []
				},
				// showType: "", //展示类型
				filterData: {
					orderBy: 'create_time', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					keyword: '',
				},
				// ReceiptData: {
				// 	userId: "",
				// 	status: ""
				// },
			};
		},
		// watch: {
		//   $route(to, from) {
		//     if (this.$route.query && this.$route.query.packId) {
		//       this.filterData.packId = this.$route.query.packId;
		//     }
		//     this.initData();
		//   }
		// },
		activated() {
			if (this.$route.query && this.$route.query.id) {
				this.id = this.$route.query.id;
			}
			this.initData();
		},
		//创建时
		created() {
			// if (this.$route.query && this.$route.query.id) {
			// 	this.id = this.$route.query.id;
			// }
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
		},
		methods: {
			initData() {
				//跳转到第一页
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];

				this.baseData = {};
				if (this.id) {
					this.getBasePageData();
				}
			},
			goBack() {
				// console.log('window.history.length',window.history.length);
				// if (window.history.length > 2) {
				// 	this.$router.go(-1);
				// } else {
				this.$router.push({
					name: 'ClientTransportInWh'
				});
				// }
			},
	
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},
			showDetList($event, row) {
				$event.stopPropagation();
				this.TraInvOutDetOpenTime = new Date().getTime();
				this.TraInvOutDetRow = row;
				this.TraInvOutDetInWhRecord = row.id;
			},

			//请求分页数据
			getBasePageData() {
				// let _this = this;
				this.loading_base = true;
				this.$http
					.get(this.$urlConfig.WhTransferEditQuery + "/" + this.id, {})
					.then(({
						data
					}) => {
						
						this.loading_base = false;
						if (200 == data.code && data.data) {
							//表格显示数据
							this.baseData = data.data;
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_d74d0bd89431d6d5'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('i18nn_d74d0bd89431d6d5'));
						this.loading_base = false;
					});
			},

			//提交信息
			// postData(url, formData, callBack) {
			// 	// let _this = this;
			// 	this.loading_base = true;
			// 	// formData.state = formData.state ? '1' : '0';
			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading_base = false;
			// 			if (200 == data.code) {
			// 				// this.dialogFormVisible = false;
			// 				// this.dialogAddFormVisible = false;
			// 				// this.initData();
			// 				callBack();
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading_base = false;
			// 			this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_transfer_Inwh_type', 'wh_transfer_arrive_way',
			// 			'wh_size_unit'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_transfer_Inwh_type = data.data['wh_transfer_Inwh_type'];
			// 				this.selectOption.wh_transfer_arrive_way = data.data['wh_transfer_arrive_way'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	
</style>
